
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rhap_header {
    align-self: center;
}

.audio-player {
    position: sticky;
    bottom: 0;
}